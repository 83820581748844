<template>
  <div>
    <div class="profile-cover">
      <div class="profile-cover-wrap">
        <img class="profile-cover-img" src="/assets/img/avatar-02.jpg" alt="" />
        <div class="cover-content">
          <div class="custom-file-btn">
            <input type="file" class="custom-file-btn-input" id="cover_upload" />
            <label class="custom-file-btn-label btn btn-sm btn-white" for="cover_upload">
              <i class="fas fa-camera"></i>
              <span class="d-none d-sm-inline-block ms-1">Update Cover</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mb-5">
      <label class="avatar avatar-xxl profile-cover-avatar" for="avatar_upload">
        <img class="avatar-img" :src="employee.profile_photo" alt="" />
        <input type="file" id="avatar_upload" @change="uploadProfilePhoto" />
        <span class="avatar-edit">
          <i class="fas fa-pen avatar-uploader-icon shadow-soft"></i>
        </span>
      </label>
      <h2>
        {{ employee.name }}
        <i class="fas fa-certificate text-primary small" data-toggle="tooltip" data-placement="top" title=""
          data-original-title="Verified"></i>
      </h2>
      <ul class="list-inline">
        <li class="list-inline-item">
          <i class="far fa-building"></i> <span>{{ employee.city }}</span>
        </li>
        <li class="list-inline-item">
          <i class="fas fa-map-marker-alt"></i> {{ employee.country }}
        </li>
        <li class="list-inline-item">
          <i class="far fa-calendar-alt"></i>
          <span> Joined {{ $helpers.dateFormat(employee.created_at) }}</span>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card card-body">

          <el-upload :auto-upload="false" :on-change="uploadResume">
            <template #trigger>
              <el-button class="m-2" type="primary">Import from Resume</el-button>
            </template>
          </el-upload>
          <el-progress v-if="loading" :percentage="50" status="warning" :indeterminate="true" />

          <h5>Complete your profile</h5>

          <div class="d-flex justify-content-between align-items-center">
            <div class="progress progress-md flex-grow-1">
              <div class="progress-bar bg-primary" role="progressbar" :style="'width:' + employee.percentage + '%'"
                :aria-valuenow="employee.percentage" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <span class="ms-4">{{ employee.percentage }}%</span>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title d-flex justify-content-between">
              <span>Profile</span>
            </h5>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0">
              <li class="py-0">
                <h6>About</h6>
              </li>
              <li>{{ employee.name }}</li>
              <li>{{ employee.country + ", " + employee.country }}</li>
              <li class="pt-2 pb-0">
                <h6>Contacts</h6>
              </li>
              <li>
                {{ employee.email }}
              </li>
              <li>{{ employee.phone }}</li>
              <li class="pt-2 pb-0">
                <h6>Education</h6>
              </li>
              <li>{{ employee.highest_qualification }}</li>
              <li class="pt-2 pb-0">
                <h6>Experience</h6>
              </li>
              <li>{{ employee.experience }}</li>
              <li class="pt-2 pb-0">
                <h6>Availability</h6>
              </li>
              <li>{{ employee.availability }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-steps class="card-header" :active="step" simple>
                <el-step title="Basic Info" />
                <el-step title="Education" />
                <el-step title="Experience" />
                <el-step title="Uploads" />
              </el-steps>
            </el-col>
          </el-row>
          <el-form :model="employee" size="large" ref="employee" label-position="top" inline status-icon>
            <div class="p-3">
              <el-row v-if="step == 1">
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                  <el-form-item label="First Name" prop="first_name" :rules="[
                    {
                      required: true,
                      message: 'First name is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="employee.first_name" placeholder="Enter first name" /> </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Middle Name">
                    <el-input v-model="employee.middle_name" placeholder="Enter middle name" /> </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Last Name" prop="last_name" :rules="[
                    {
                      required: true,
                      message: 'Last name is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="employee.last_name" placeholder="Enter last name" /> </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Country" prop="country">
                    <el-select v-model="employee.country" filterable placeholder="Select country">
                      <el-option v-for="country in $store.state.dictionary.countries" :key="country"
                        :label="country.name" :value="country.name" />
                    </el-select>
                  </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Phone Number" prop="phone" :rules="[
                    {
                      required: true,
                      message: 'Phone number is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="employee.phone" type="number" placeholder="Enter phone number" />
                  </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Email Address" prop="email" :rules="[
                    {
                      required: true,
                      message: 'Email address is required',
                      trigger: ['blur'],
                    },
                    {
                      type: 'email',
                      message: 'Please enter valid address',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="employee.email" type="email" placeholder="Enter email address" />
                  </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Date of Birth">
                    <el-date-picker v-model="employee.date_of_birth" type="date" placeholder="Enter date of birth"
                      style="width: 100%" />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Gender">
                    <el-select v-model="employee.gender" filterable placeholder="Select gender">
                      <el-option label="Male" value="male" />
                      <el-option label="Female" value="female" />
                      <el-option label="Other" value="other" />
                    </el-select> </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Marital Status">
                    <el-select v-model="employee.marital_status" filterable placeholder="Select marital status">
                      <el-option label="Single" value="single" />
                      <el-option label="Married" value="married" />
                      <el-option label="Divorced" value="divorced" />
                      <el-option label="Widow" value="widow" />
                      <el-option label="Widower" value="widower" />
                    </el-select> </el-form-item></el-col>

                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Which is your Highest Academic Qualification?">
                    <el-select v-model="employee.highest_qualification" placeholder="Select highest qualification"
                      filterable>
                      <el-option value="PHD">PHD</el-option>
                      <el-option value="Bachelor">Bachelor</el-option>
                      <el-option value="Masters">Masters</el-option>
                      <el-option value="Diploma">Diploma</el-option>
                      <el-option value="Postgrad">Postgrad</el-option>
                      <el-option value="Certificate">Certificate</el-option>
                      <el-option value="KCSE">KCSE</el-option>
                      <el-option value="KCPE">KCPE</el-option>
                      <el-option value="Other">Other</el-option>
                    </el-select>
                  </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="What is your Current or Latest Position (if any)?">
                    <el-input v-model="employee.industry" placeholder="Enter current or latest held position" />
                  </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="How many years of Experience do you have?">
                    <el-select v-model="employee.experience" placeholder="Select years of experience" filterable>
                      <el-option value="0">No Experience/Less than 1 year</el-option>
                      <el-option value="1 year">1 year</el-option>
                      <el-option value="2 years">2 years</el-option>
                      <el-option value="3 years">3 years</el-option>
                      <el-option value="4 years">4 years</el-option>
                      <el-option value="5 years">5 years</el-option>
                      <el-option value="6 years">6 years</el-option>
                      <el-option value="7 years">7 years</el-option>
                      <el-option value="8 years">8 years</el-option>
                      <el-option value="9 years">9 years</el-option>
                      <el-option value="10 years">10 years</el-option>
                      <el-option value="11 years">11 years</el-option>
                      <el-option value="12 years">12 years</el-option>
                      <el-option value="13 years">13 years</el-option>
                      <el-option value="14 years">14 years</el-option>
                      <el-option value="15 years">15 years</el-option>
                      <el-option value="16 years">16 years</el-option>
                      <el-option value="17 years">17 years</el-option>
                      <el-option value="18 years">18 years</el-option>
                      <el-option value="19 years">19 years</el-option>
                      <el-option value="20 years">20 years</el-option>
                      <el-option value="More than 20 years">More than 20 years</el-option>
                    </el-select>
                  </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="When are you Available?">
                    <el-select v-model="employee.availability" placeholder="Select availability" filterable>
                      <el-option value="Immediately">Immediately</el-option>
                      <el-option value="1 Week">1 Week</el-option>
                      <el-option value="2 Weeks">2 Weeks</el-option>
                      <el-option value="3 Weeks">3 Weeks</el-option>
                      <el-option value="1 Month">1 Month</el-option>
                      <el-option value="2 Months">2 Months</el-option>
                      <el-option value="More Than 3 Months">More Than 3 Months</el-option>
                    </el-select>
                  </el-form-item></el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Please Select Your Ethinicity">
                    <el-select v-model="employee.ethinicity" placeholder="Select your ethinicity" filterable>
                      <el-option v-for="ethinicity_group in ethinicity_groups" :value="ethinicity_group.name">{{
                        ethinicity_group.name }}</el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Please Select Your County">
                    <el-select v-model="employee.county" placeholder="Select your county" filterable>
                      <el-option v-for="county in counties" :value="county.name">{{ county.name }}</el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="Are you a person living with Disability?">
                    <el-select v-model="employee.dissabled" placeholder="Select" filterable>
                      <el-option value="NO">NO</el-option>
                      <el-option value="YES">YES</el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <el-form-item label="If YES, indicate your NCPWD Reg No.">
                    <el-input v-model="education.ncpwd_reg" placeholder="Enter NCPWD Reg No" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-else-if="step == 2">
                <el-col :span="12">
                  <el-form-item label="Institution Name" prop="institution" :rules="[
                    {
                      required: true,
                      message: 'Institution name is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="education.institution" placeholder="Enter institution name" />
                  </el-form-item></el-col>
                <el-col :span="12">
                  <el-form-item label="Course" prop="course" :rules="[
                    {
                      required: true,
                      message: 'Course name is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="education.course" placeholder="Enter course name" /> </el-form-item></el-col>
                <el-col :span="8">
                  <el-form-item label="Grade" prop="grade" :rules="[
                    {
                      required: true,
                      message: 'Grade is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="education.grade" placeholder="Enter grade" /> </el-form-item></el-col>
                <el-col :span="8">
                  <el-form-item label="Start Date" prop="start_date" :rules="[
                    {
                      required: true,
                      message: 'Start date is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-date-picker v-model="education.start_date" type="date" placeholder="Enter start date"
                      style="width: 100%" /></el-form-item></el-col>
                <el-col :span="8">
                  <el-form-item label="End Date" prop="end_date">
                    <el-date-picker v-model="education.end_date" type="date" placeholder="Enter end date"
                      style="width: 100%" /></el-form-item></el-col>
                <el-col :span="24">
                  <el-form-item label="Description" prop="description">
                    <el-input type="textarea" v-model="education.description" placeholder="Enter more details" />
                  </el-form-item></el-col>
                <el-col :span="24" class="p-1">
                  <el-button type="success" plain @click="saveEducation('employee')" :loading="loading">Save</el-button>
                </el-col>
                <el-table :data="employee.education" ref="elTable" v-loading="loading">
                  <el-table-column prop="institution" label="Institution" />
                  <el-table-column prop="course" label="Course" />
                  <el-table-column prop="grade" label="Grade" />
                  <el-table-column prop="start_date" label="Start Date" :formatter="$helpers.formatDate" />
                  <el-table-column prop="end_date" label="End Date" :formatter="$helpers.formatDate" />
                  <el-table-column prop="action" label="Action" width="80">
                    <template #default="props">
                      <el-dropdown>
                        <i class="fas fa-ellipsis-v el-icon--right"></i>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item @click="deleteEducation(props.row.id)">Delete</el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
              <el-row v-else-if="step == 3">
                <el-col :span="12">
                  <el-form-item label="Empolyer's Name" prop="employer" :rules="[
                    {
                      required: true,
                      message: 'Empolyer name is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="experience.employer" placeholder="Enter employer's name" />
                  </el-form-item></el-col>
                <el-col :span="12">
                  <el-form-item label="Position" prop="job_title" :rules="[
                    {
                      required: true,
                      message: 'Job title is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-input v-model="experience.job_title" placeholder="Enter job title" /> </el-form-item></el-col>
                <el-col :span="12">
                  <el-form-item label="Start Date" prop="start_date" :rules="[
                    {
                      required: true,
                      message: 'Start date is required',
                      trigger: ['blur'],
                    },
                  ]">
                    <el-date-picker v-model="experience.start_date" type="date" placeholder="Enter start date"
                      style="width: 100%" /></el-form-item></el-col>
                <el-col :span="12">
                  <el-form-item label="End Date" prop="end_date">
                    <el-date-picker v-model="experience.end_date" type="date" placeholder="Enter end date"
                      style="width: 100%" /></el-form-item></el-col>
                <el-col :span="12">
                  <el-form-item label="Responsibilities" prop="responsibilities">
                    <el-input type="textarea" v-model="experience.responsibilities"
                      placeholder="Enter responsibilities" />
                  </el-form-item></el-col>
                <el-col :span="12">
                  <el-form-item label="Achievements" prop="achivements">
                    <el-input type="textarea" v-model="experience.achivements" placeholder="Enter Achievements" />
                  </el-form-item></el-col>

                <el-col :span="24" class="p-1">
                  <el-button type="success" plain @click="saveExperience('employee')"
                    :loading="loading">Save</el-button>
                </el-col>
                <el-table :data="employee.work_experience" ref="elTable" v-loading="loading">
                  <el-table-column prop="employer" label="Employer" />
                  <el-table-column prop="job_title" label="Position" />
                  <el-table-column prop="start_date" label="Start Date" :formatter="$helpers.formatDate" />
                  <el-table-column prop="end_date" label="End Date" :formatter="$helpers.formatDate" />
                  <el-table-column prop="action" label="Action" width="80">
                    <template #default="props">
                      <el-dropdown>
                        <i class="fas fa-ellipsis-v el-icon--right"></i>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item @click="deleteExperience(props.row.id)">Delete</el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
              <el-row v-else-if="step == 4">
                <el-col :span="12">
                  <el-form-item label="Document Type" prop="document_type">
                    <el-select v-model="employee.document_type" filterable placeholder="Select document type">
                      <el-option
                        v-for="document in ['Curriculum Vitae', 'Cover Letter', 'Recommendation Letter', 'Educational Transcript', 'Other Certifications']"
                        :key="document" :label="document" :value="document" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Document Title" prop="document_title">
                    <el-input v-model="employee.document_title" placeholder="Enter document title" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Choose Document" prop="document">
                    <el-upload :auto-upload="false" :on-change="handleFileUpload" drag>
                      <i class="fas fa-cloud el-icon--upload"></i>
                      <div class="el-upload__text">
                        <em>Choose document to upload</em>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-table :data="employee.documents" ref="elTable" v-loading="loading">
                  <el-table-column prop="document_title" label="Title" />
                  <el-table-column prop="document_type" label="Type" />
                  <el-table-column prop="filesize" label="Size" />
                  <el-table-column prop="action" label="Action" width="80">
                    <template #default="props">
                      <el-dropdown>
                        <i class="fas fa-ellipsis-v el-icon--right"></i>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <a :href="props.row.url" target="_blank">
                              <el-dropdown-item>Download</el-dropdown-item>
                            </a>
                            <el-dropdown-item @click="deleteDocument(props.row.id)">Delete</el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
            </div>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24" class="pb-4 ps-4">
                <el-button v-if="step != 1" type="success" plain @click="back()">Back</el-button>
                <el-button v-if="step == 1" type="success" plain @click="submit('employee')">Next</el-button>
                <el-button v-if="step == 2" type="success" plain @click="step = 3" :loading="loading">Next</el-button>
                <el-button v-if="step == 3" type="success" plain @click="step = 4" :loading="loading">Next</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialog" title="Update Career Profile" width="70%">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var self = this;
    return {
      data: "",
      step: 1,
      loading: false,
      dialog: false,
      employee: {},
      education: {},
      experience: {},

      counties: [{
        "name": "Mombasa",
        "code": 1,
        "capital": "Mombasa City"
      }, {
        "name": "Kwale",
        "code": 2,
        "capital": "Kwale"
      }, {
        "name": "Kilifi",
        "code": 3,
        "capital": "Kilifi"
      }, {
        "name": "Tana River",
        "code": 4,
        "capital": "Hola"

      }, {
        "name": "Lamu",
        "code": 5,
        "capital": "Lamu"
      }, {
        "name": "Taita-Taveta",
        "code": 6,
        "capital": "Voi"
      }, {
        "name": "Garissa",
        "code": 7,
        "capital": "Garissa"
      }, {
        "name": "Wajir",
        "code": 8,
        "capital": "Wajir"
      }, {
        "name": "Mandera",
        "code": 9,
        "capital": "Mandera"
      }, {
        "name": "Marsabit",
        "code": 10,
        "capital": "Marsabit"
      }, {
        "name": "Isiolo",
        "code": 11,
        "capital": "Isiolo"
      }, {
        "name": "Meru",
        "code": 12,
        "capital": "Meru"
      }, {
        "name": "Tharaka-Nithi",
        "code": 13,
        "capital": "Chuka"
      }, {
        "name": "Embu",
        "code": 14,
        "capital": "Embu"
      }, {
        "name": "Kitui",
        "code": 15,
        "capital": "Kitui"
      }, {
        "name": "Machakos",
        "code": 16,
        "capital": "Machakos"
      }, {
        "name": "Makueni",
        "code": 17,
        "capital": "Wote"
      }, {
        "name": "Nyandarua",
        "code": 18,
        "capital": "Ol Kalou"
      }, {
        "name": "Nyeri",
        "code": 19,
        "capital": "Nyeri"
      }, {
        "name": "Kirinyaga",
        "code": 20,
        "capital": "Kerugoya/Kutus"
      }, {
        "name": "Murang'a",
        "code": 21,
        "capital": "Murang'a"
      }, {
        "name": "Kiambu",
        "code": 22,
        "capital": "Kiambu"
      }, {
        "name": "Turkana",
        "code": 23,
        "capital": "Lodwar"
      }, {
        "name": "West Pokot",
        "code": 24,
        "capital": "Kapenguria"
      }, {
        "name": "Samburu",
        "code": 25,
        "capital": "Maralal"
      }, {
        "name": "Trans-Nzoia",
        "code": 26,
        "capital": "Kitale"
      }, {
        "name": "Uasin Gishu",
        "code": 27,
        "capital": "Eldoret"
      }, {
        "name": "Elgeyo-Marakwet",
        "code": 28,
        "capital": "Iten"
      }, {
        "name": "Nandi",
        "code": 29,
        "capital": "Kapsabet"
      }, {
        "name": "Baringo",
        "code": 30,
        "capital": "Kabarnet"
      }, {
        "name": "Laikipia",
        "code": 31,
        "capital": "Rumuruti"
      }, {
        "name": "Nakuru",
        "code": 32,
        "capital": "Nakuru"
      }, {
        "name": "Narok",
        "code": 33,
        "capital": "Narok"
      }, {
        "name": "Kajiado",
        "code": 34
      }, {
        "name": "Kericho",
        "code": 35,
        "capital": "Kericho"
      }, {
        "name": "Bomet",
        "code": 36,
        "capital": "Bomet"
      }, {
        "name": "Kakamega",
        "code": 37,
        "capital": "Kakamega"
      }, {
        "name": "Vihiga",
        "code": 38,
        "capital": "Vihiga"
      }, {
        "name": "Bungoma",
        "code": 39,
        "capital": "Bungoma"
      }, {
        "name": "Busia",
        "code": 40,
        "capital": "Busia"
      }, {
        "name": "Siaya",
        "code": 41,
        "capital": "Siaya"
      }, {
        "name": "Kisumu",
        "code": 42,
        "capital": "Kisumu"
      }, {
        "name": "Homa Bay",
        "code": 43,
        "capital": "Homa Bay"
      }, {
        "name": "Migori",
        "code": 44,
        "capital": "Migori"
      }, {
        "name": "Kisii",
        "code": 45,
        "capital": "Kisii"
      }, {
        "name": "Nyamira",
        "code": 46,
        "capital": "Nyamira"
      }, {
        "name": "Nairobi",
        "code": 47,
        "capital": "Nairobi City"
      }],
      ethinicity_groups: [
        { "name": "Kikuyu" },
        { "name": "Luo" },
        { "name": "Kalenjin" },
        { "name": "Kamba" },
        { "name": "Bantu" },
        { "name": "Luhya" },
        { "name": "Nilotic" },
        { "name": "Meru" },
        { "name": "Kisii" },
        { "name": "Maasai" },
        { "name": "Asian" },
        { "name": "Arabs" },
        { "name": "Turkana" },
        { "name": "Oromo" },
        { "name": "Mijikenda" },
        { "name": "Embu" },
        { "name": "Indians in Kenya" },
        { "name": "Teso" },
        { "name": "Somalis" },
        { "name": "Samburu" },
        { "name": "Dorobo" },
        { "name": "Kenyan Americans" },
        { "name": "Rendille" },
        { "name": "Gabra" },
        { "name": "Taita" },
        { "name": "Sakuye" },
        { "name": "Boorana" },
        { "name": "Cushitic" },
        { "name": "Okiek" },
        { "name": "Bajuni" },
        { "name": "Tana Orma" },
        { "name": "Taveta" },
        { "name": "Kuria" },
        { "name": "Suba" },
        { "name": "Chok" },
        { "name": "Chemwal" },
        { "name": "Athi" },
        { "name": "Konso" },
        { "name": "Ilchamus" },
        { "name": "Mbeere" },
        { "name": "Maragoli" },
        { "name": "Pokomo" },
        { "name": "Jibana" },
        { "name": "Kipsigis" },
        { "name": "Tugen" },
        { "name": "Pokot" },
        { "name": "Banyala" },
        { "name": "Degoodi" },
        { "name": "Banyole" },
        { "name": "Bukusu" },
        { "name": "Idakho" },
        { "name": "Other" }
      ]
    };
  },
  created() {
    this.getCareerProfile();
    this.$store.dispatch("dictionary/getCountries");
  },
  methods: {
    next(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.step++;
        } else {
          return false;
        }
      });
    },
    back() {
      this.step--;
    },
    getCareerProfile() {
      // var profile_id = JSON.parse(localStorage.getItem("profile_id"));
      this.$endpoint.getCareerProfile(this.$store.state.auth.user.id).then(
        (res) => {
          this.loading = false;
          this.employee = res.data.data;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },
    submit(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$endpoint.savePersonnel(this.employee).then(
            () => {
              this.loading = false;
              this.step = 2;
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.$notify({
                title: "Error",
                message: this.message,
                type: "error",
              });
            }
          );
        } else {
          return false;
        }
      });
    },
    saveEducation() {
      this.loading = true;
      this.$endpoint.saveEducation(this.education).then(
        () => {
          this.loading = false;
          this.step = 3;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },
    saveExperience() {
      this.loading = true;
      this.$endpoint.saveExperience(this.experience).then(
        () => {
          this.loading = false;
          this.step = 1;
          this.$notify({
            title: "Success",
            message: "Profile has been updated",
            type: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },

    async uploadProfilePhoto(event) {
      let file = event.target.files[0];
      await this.$helpers
        .getBase64(file)
        .then((data) => (this.employee.profile_photo = data));

      this.loading = true;
      this.$endpoint.saveUser(this.employee).then(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },

    handleFileUpload(e) {
      if (!this.employee.document_type || !this.employee.document_title) {
        this.$notify({
          title: "Error",
          message: "Document title and type are required",
          type: "error",
        });
        return;
      }
      this.loading = true;
      this.file = e.raw;
      let formData = new FormData();
      formData.append("document_type", this.employee.document_type);
      formData.append("document_title", this.employee.document_title);
      formData.append("file", this.file);
      this.$endpoint.saveDocument(formData).then(
        (res) => {
          this.loading = false;
          this.getCareerProfile();
          this.$notify({
            title: "Success",
            message: res.data.message,
            type: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },

    deleteDocument(id) {
      if (confirm("Do you really want to delete this record?")) {
        this.$endpoint.deleteDocument(id).then(
          (res) => {
            this.loading = false;
            this.getCareerProfile();
            this.$notify({
              title: "Success",
              message: res.data.message,
              type: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$notify({
              title: "Error",
              message: this.message,
              type: "error",
            });
          }
        );
      }
    },
    deleteEducation(id) {
      if (confirm("Do you really want to delete this record?")) {
        this.$endpoint.deleteEducation(id).then(
          (res) => {
            this.loading = false;
            this.getCareerProfile();
            this.$notify({
              title: "Success",
              message: res.data.message,
              type: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$notify({
              title: "Error",
              message: this.message,
              type: "error",
            });
          }
        );
      }
    },

    uploadResume(e) {
      this.loading = true;
      this.file = e.raw;
      let formData = new FormData();
      formData.append("file", this.file);
      this.$endpoint
        .uploadResume(formData)
        .then(
          (res) => {
            this.getCareerProfile();
            this.loading = false;
            this.$notify({
              title: "Success",
              message: res.data.message,
              type: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$notify({
              title: "Error",
              message: this.message,
              type: "error",
            });
          }
        );
    },

    deleteExperience(id) {
      if (confirm("Do you really want to delete this record?")) {
        this.$endpoint.deleteExperience(id).then(
          (res) => {
            this.loading = false;
            this.getCareerProfile();
            this.$notify({
              title: "Success",
              message: res.data.message,
              type: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$notify({
              title: "Error",
              message: this.message,
              type: "error",
            });
          }
        );
      }
    },
  },
};
</script>
