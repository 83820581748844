<template>
  <el-card>
    <template #header>
      <span>System Users</span>
      <el-button type="success" plain @click="dialog = true">Add User</el-button>
    </template>
    <el-dialog v-model="dialog" :title="getTitle" :before-close="handleClose">
      <el-form :model="user" size="large" ref="user" label-position="top" :inline="true">
        <el-row>
          <el-col :span="24">
            <el-form-item label="Name" prop="name" :rules="[
              {
                required: true,
                message: 'Name is required',
                trigger: ['blur'],
              },
            ]">
              <el-input v-model="user.name" placeholder="Enter name" /> </el-form-item></el-col>
          <el-col :span="12">
            <el-form-item label="Phone Number" prop="phone" :rules="[
              {
                required: true,
                message: 'Phone number is required',
                trigger: ['blur'],
              },
            ]">
              <el-input v-model="user.phone" placeholder="Enter phone number" /> </el-form-item></el-col>
          <el-col :span="12">
            <el-form-item label="Email Address" prop="email" :rules="[
              {
                required: true,
                message: 'Email address is required',
                trigger: ['blur'],
              },
            ]">
              <el-input v-model="user.email" placeholder="Enter email address" /> </el-form-item></el-col>
          <el-col :span="24">
            <el-form-item label="Role" prop="role_id" :rules="[
              {
                required: true,
                message: 'User role is required',
                trigger: ['blur'],
              },
            ]">
              <el-select v-model="user.role_id" placeholder="Select user role">
                <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id" />
              </el-select> </el-form-item></el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">Cancel</el-button>
          <el-button type="success" plain @click="submit(user)" :loading="loading">Save</el-button>
        </span>
      </template>
    </el-dialog>
    <el-table :data="users" ref="elTable" v-loading="loading">
      <el-table-column prop="name" label="Name" width="250">
        <template #default="scope">
          <router-link to="/settings/users">
            <div style="display: flex; align-items: center">
              <el-avatar v-if="scope.row.profile_photo" :src="scope.row.profile_photo" />
              <el-avatar v-else>
                {{ $helpers.getInitials(scope.row.name) }}
              </el-avatar>
              <span style="margin-left: 10px"><span class="text-black">{{ scope.row.name }}<br /></span>{{
                  scope.row.role
              }}</span>
            </div>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="Phone" />
      <el-table-column prop="email" label="Email" />
      <!-- <el-table-column prop="status" label="Status">
        <template #default="scope"
          ><span class="text-black">{{ scope.row.status }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="action" label="Action" width="70">
        <template #default="props">
          <el-dropdown>
            <i class="fas fa-ellipsis-v el-icon--right"></i>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="edit(props.row)">Update</el-dropdown-item>
                <el-dropdown-item @click="sendLoginCredentials(props.row.id)">Send Login Credentials</el-dropdown-item>
                <el-dropdown-item v-if="props.row.role != 'Admin'"
                  @click="deleteUser(props.row.id)">Delete</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      users: [],
      user: {},
      roles: [],
    };
  },

  computed: {
    getTitle() {
      return this.user.id ? "Edit User" : "Create New User";
    },
  },
  mounted() {
    this.getUsers();
    this.getRoles();
  },
  methods: {
    edit(data) {
      this.user = data;
      this.dialog = true;
    },
    getUsers() {
      this.loading = true;
      let data = {};
      data.company_id = this.$store.state.auth.user.company_id;
      this.$endpoint
        .getUsers(data)
        .then((response) => {
          this.loading = false;
          this.users = response.data.data;
        })
        .catch(() => { });
    },

    getRoles() {
      let data = {};
      data.company_id = this.$store.state.auth.user.company_id;
      return this.$endpoint
        .getRole(data)
        .then((response) => {
          this.roles = response.data.data.roles;
        })
        .catch(() => { });
    },

    submit(data) {
      this.loading = true;
      data.company_id = this.$store.state.auth.user.company_id;

      this.$endpoint.saveUser(data).then(
        (response) => {
          this.loading = false;
          this.user = {};
          this.getUsers();
          this.dialog = false;
          this.$notify({
            title: "Success",
            message: response.data.message,
            type: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },
    deleteUser(id) {
      if (confirm("Do you really want to delete this user?")) {
        this.$endpoint.deleteUser(id).then(
          (response) => {
            this.getUsers();
            this.$notify({
              title: "Success",
              message: response.data.message,
              type: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            this.$notify({
              title: "Error",
              message: this.message,
              type: "error",
            });
          }
        );
      }
    },
    sendLoginCredentials(id) {
      this.loading = true;
      this.$endpoint.sendLoginCredentials(id).then(
        (response) => {
          this.loading = false;
          this.getUsers();
          this.$notify({
            title: "Success",
            message: response.data.message,
            type: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },
    handleClose() {
      this.user = {};
      this.dialog = false;
    },
  },
};
</script>